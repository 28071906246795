<template>
  <div class="section" id="portfolio">
    <div class="section_inner">
      <div class="portfolio swiper-section">
        <div class="main_title">
          <h3>Creative <span class="coloring">Portfolio</span></h3>
        </div>
        <div class="portfolio_list gallery_zoom">
          <swiper
            :loop="false"
            :slidesPerView="1"
            :spaceBetween="0"
            :loopAdditionalSlides="1"
            :autoplay="{
              delay: 6000,
            }"
            :navigation="{
              nextEl: '.my_next',
              prevEl: '.my_prev',
            }"
            :pagination="pagination"
            :breakpoints="{
              700: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }"
            @slideChange="onSlideChange"
            :modules="modules"
            class="swiper-container"
          >
            <swiper-slide class="swiper-slide">
              <div class="list_inner">
                <div class="image">
                  <img src="img/thumbs/1-1.jpg" alt="" />
                  <div class="main" data-img-url="img/portfolio/1.jpg"></div>
                </div>
                <div class="details">
                  <h3>aiAgency</h3>
                  <span>Web App</span>
                </div>
                <a
                  class="full_link popup"
                  href="https://www.aiagency.app/"
                ></a>
              </div>
            </swiper-slide>
            <swiper-slide class="swiper-slide">
              <div class="list_inner">
                <div class="image">
                  <img src="img/thumbs/1-1.jpg" alt="" />
                  <div class="main" data-img-url="img/portfolio/2.jpg"></div>
                </div>
                <div class="details">
                  <h3>Digital Hackers</h3>
                  <span>e-commerce</span>
                </div>
                <a
                  class="full_link popup"
                  href="https://www.digitalhackers.com/embed/bUUfgObhgC0"
                ></a>
              </div>
            </swiper-slide>
            <swiper-slide class="swiper-slide">
              <div class="list_inner">
                <div class="image">
                  <img src="img/thumbs/1-1.jpg" alt="" />
                  <div class="main" data-img-url="img/portfolio/3.jpg"></div>
                </div>
                <div class="details">
                  <h3>Anasanas App</h3>
                  <span>App</span>
                </div>
                <a
                  class="full_link popup-iframe"
                  href="https://asanas-software-quality.vercel.app/en"
                ></a>
              </div>
            </swiper-slide>
            <swiper-slide class="swiper-slide">
              <div class="list_inner">
                <div class="image">
                  <img src="img/thumbs/1-1.jpg" alt="" />
                  <div class="main" data-img-url="img/portfolio/4.jpg"></div>
                </div>
                <div class="details">
                  <h3>Admin Panel AilineX</h3>
                  <span>Dashboard</span>
                </div>
                <a class="full_link portfolio_popup" href="#"></a>

                <!-- Portfolio Popup Informations -->
                <div class="hidden_content">
                  <div class="portfolio_popup_details">
                    <div class="top_image">
                      <img src="img/thumbs/4-2.jpg" alt="" />
                      <div
                        class="main"
                        data-img-url="img/portfolio/4.jpg"
                        style="background-image: url('img/portfolio/4.jpg')"
                      ></div>
                    </div>
                    <div class="details">
                      <h3>AirlineX</h3>
                      <span>Flight Dashboard</span>
                    </div>
                    <div class="text">
                      <p>
                        Its a ticket booking system for airlines. The app allows
                        users to book tickets, check flight status, and manage
                        their booking. The app is designed to be user-friendly
                        and easy to use. The app is available on both iOS and
                        Android devices.
                      </p>
                      <p>
                        The Ai Dev Group improved the app adding AI features
                        that help users find the best deals on flights. The app
                        also has a chatbot feature that helps users with any
                        questions they may have. The app is designed to be
                        user-friendly and easy to use. The app is available on
                        both iOS and Android devices.
                      </p>
                    </div>
                    <div class="additional_images">
                      <ul class="gallery_zoom">
                        <li>
                          <div class="list_inner">
                            <div class="image">
                              <img src="img/thumbs/4-2.jpg" alt="" />
                              <div
                                class="main"
                                data-img-url="img/portfolio/7.jpg"
                              ></div>
                              <a
                                class="full_link zoom"
                                href="img/portfolio/7.jpg"
                              ></a>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="list_inner">
                            <div class="image">
                              <img src="img/thumbs/4-2.jpg" alt="" />
                              <div
                                class="main"
                                data-img-url="img/portfolio/8.jpg"
                              ></div>
                              <a
                                class="full_link zoom"
                                href="img/portfolio/8.jpg"
                              ></a>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="list_inner">
                            <div class="image">
                              <img src="img/thumbs/4-2.jpg" alt="" />
                              <div
                                class="main"
                                data-img-url="img/portfolio/9.jpg"
                              ></div>
                              <a
                                class="full_link zoom"
                                href="img/portfolio/9.jpg"
                              ></a>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <!-- /Portfolio Popup Informations -->
              </div>
            </swiper-slide>
            <swiper-slide class="swiper-slide">
              <div class="list_inner">
                <div class="image">
                  <img src="img/thumbs/1-1.jpg" alt="" />
                  <div class="main" data-img-url="img/portfolio/5.jpg"></div>
                </div>
                <div class="details">
                  <h3>New App</h3>
                  <span>Mockup</span>
                </div>
                <a
                  class="full_link zoom"
                  href="img/portfolio/5.jpg"
                ></a>
              </div>
            </swiper-slide>
            <swiper-slide class="swiper-slide">
              <div class="list_inner">
                <div class="image">
                  <img src="img/thumbs/1-1.jpg" alt="" />
                  <div class="main" data-img-url="img/portfolio/6.jpg"></div>
                </div>
                <div class="details">
                  <h3>New app 2</h3>
                  <span>Mockup</span>
                </div>
                <a
                  class="full_link zoom"
                  href="img/portfolio/6.jpg"
                ></a>
              </div>
            </swiper-slide>
            <div class="swiper_progress fill">
              <div class="my_pagination_in">
                <span class="current">0</span>
                <span class="pagination_progress"
                  ><span class="all"
                    ><span
                      style="
                        transform: translate3d(0px, 0px, 0px) scaleX(0.75)
                          scaleY(1);
                      "
                    ></span></span
                ></span>
                <span class="total">04</span>
              </div>
              <div class="my_navigation">
                <ul>
                  <li>
                    <a class="my_prev" href="#"
                      ><i class="icon-left-open-1"></i
                    ></a>
                  </li>
                  <li>
                    <a class="my_next" href="#"
                      ><i class="icon-right-open-1"></i
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Swiper core and required modules

// Import Swiper Vue.js components
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import { swiperSliderCustomSlider } from "../utilits";

export default {
  name: `PortfolioComponent`,
  components: {
    Swiper,
    SwiperSlide,
  },
  methods: {
    onSlideChange(swiper) {
      this.activeSlider = swiper.activeIndex + 1;
    },
  },
  setup() {
    return {
      modules: [Navigation, Pagination],
      pagination: {
        el: ".swiper_progress",
        type: "custom", // progressbar
        renderCustom: function (swiper, current, total) {
          swiperSliderCustomSlider(current, total);
        },
      },
    };
  },
};
</script>
